import { default as cx } from "classnames";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { menuLinks } from "../../resources/menu";
import { FaChevronDown } from "react-icons/fa";

function SubMenu({
  location,
  menuItem,
  setMobileMenuOpened,
  setSubmenuOpened,
  submenuOpened,
}) {
  return (
    <li
      key={menuItem.key}
      className={cx({
        active: menuItem.children.some(
          (item) => item.url === location.pathname
        ),
      })}
    >
      <span
        onClick={() =>
          setSubmenuOpened(submenuOpened === menuItem.key ? -1 : menuItem.key)
        }
      >
        {menuItem.label}
        <FaChevronDown
          className={cx("chevron-menu", {
            opened: submenuOpened === menuItem.key,
          })}
        />
      </span>
      <div className="js-underline" />
      <ul
        className={cx("submenu", { displayed: submenuOpened === menuItem.key })}
      >
        {menuItem.children.map((submenuItem) => (
          <li key={submenuItem.key}>
            <Link
              to={submenuItem.url}
              onClick={() => {
                setMobileMenuOpened(false);
                setSubmenuOpened(-1);
              }}
            >
              {submenuItem.label}
              {submenuItem.icon}
              <span className="js-underline2" />
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
}

function Default({ mobileMenuOpened, setMobileMenuOpened }) {
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const [submenuOpened, setSubmenuOpened] = useState(-1);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 50);
  }, []);

  return (
    <nav
      className={cx("main-menu", {
        opened: mobileMenuOpened,
        "hidden-sub": !loaded,
      })}
      id="main-menu"
    >
      <ul className="menu">
        <li key={-1} className={"hidden"} />
        {menuLinks.map((menuItem) => {
          if (menuItem.key === "Social Networks") {
            return (
              <li key={menuItem.key}>
                <a
                  href={"https://www.facebook.com/ttblagnacais31"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <FaFacebookSquare />
                </a>
                <a
                  href={"https://www.instagram.com/tt_blagnac_/"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <FaInstagram />
                </a>
              </li>
            );
          }
          if (!menuItem.children) {
            return (
              <li
                key={menuItem.key}
                className={cx({
                  active:
                    menuItem.label === "Accueil"
                      ? menuItem.url === location.pathname
                      : location.pathname.startsWith(menuItem.url),
                })}
              >
                <Link
                  to={menuItem.url}
                  onClick={() => setMobileMenuOpened(false)}
                >
                  {menuItem.label}
                  {menuItem.icon}
                  <span className="js-underline" />
                </Link>
              </li>
            );
          }
          return (
            <SubMenu
              key={menuItem.key}
              menuItem={menuItem}
              location={location}
              setMobileMenuOpened={setMobileMenuOpened}
              submenuOpened={submenuOpened}
              setSubmenuOpened={setSubmenuOpened}
            />
          );
        })}
      </ul>
    </nav>
  );
}

export const MainMenu = React.memo(Default);
