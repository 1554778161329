import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";

function ReadMore(props) {
  return (
    <Link to={props.to} className="read-more">
      Lire la suite
      <FaChevronRight />
    </Link>
  );
}

export default ReadMore;
