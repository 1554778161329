import { useEffect, useState } from "react";

export default function useFetch(
  url,
  shouldStore,
  loadingMinimalTimeout,
  adaptator
) {
  const [data, setData] = useState(null);
  useEffect(() => {
    async function loadData() {
      if (
        shouldStore &&
        JSON.parse(localStorage.getItem(shouldStore)) &&
        Date.now() - JSON.parse(localStorage.getItem(shouldStore)).storedTime <
          3600000
      ) {
        setTimeout(() => {
          setData(JSON.parse(localStorage.getItem(shouldStore)));
        }, loadingMinimalTimeout);
      } else {
        setData({ data: [], headers: {}, status: "LOADING" });
        const start = Date.now();
        const response = await fetch("https://backend.ttblagnacais.fr" + url);
        if (!response.ok) {
          // oups! something went wrong
          return;
        }
        const posts = await response.json();
        const end = Date.now();
        const timeOut =
          loadingMinimalTimeout - (end - start) < 0
            ? 0
            : loadingMinimalTimeout - (end - start);

        if (shouldStore) {
          localStorage.setItem(
            shouldStore,
            JSON.stringify({
              data: posts,
              headers: response.headers,
              status: "LOADED",
              storedTime: start,
            })
          );
        }

        setTimeout(() => {
          setData({
            data: adaptator ? adaptator(posts) : posts,
            headers: response.headers,
            status: "LOADED",
          });
        }, timeOut);
      }
    }

    loadData();
  }, [url, loadingMinimalTimeout, shouldStore]);
  return data;
}
