export const getPostImage = (post) => {
  return post.featured_image_medium
    ? post.featured_image_medium
    : "https://backend.ttblagnacais.fr/wp-content/themes/twentytwentythree-child/img/default-post-img--" +
        post.acf.image_par_defaut_si_pas_dimage_ajoutee_manuellement.split(
          ":"
        )[0] +
        ".webp";
};

export const getFullPostImage = (post) => {
  return post.featured_image_large
    ? post.featured_image_large
    : "https://backend.ttblagnacais.fr/wp-content/themes/twentytwentythree-child/img/default-post-img--" +
        post.acf.image_par_defaut_si_pas_dimage_ajoutee_manuellement.split(
          ":"
        )[0] +
        ".webp";
};

export const getCustomThumbnail = (post) => {
  return post.featured_image_custom_thumbnail
    ? post.featured_image_custom_thumbnail
    : "https://backend.ttblagnacais.fr/wp-content/themes/twentytwentythree-child/img/default-post-img--" +
        post.acf.image_par_defaut_si_pas_dimage_ajoutee_manuellement.split(
          ":"
        )[0] +
        ".webp";
};
