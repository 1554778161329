import { ResponsiveLine } from "@nivo/line";

export const Historic = ({ histo }) => {
  const finalData = {
    id: "Points",
    color: "#ee3962",
    data: histo.data.liste.histo.map((item) => ({
      x: item.saison + " (P" + item.phase + ")",
      y: item.point,
    })),
  };

  return (
    <div className="histo">
      <ResponsiveLine
        data={[finalData]}
        colors={["#ee3962"]}
        margin={{ right: 40, bottom: 30, left: 40, top: 5 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        tooltip={(item) => {
          return (
            <div className="tooltip-graph">
              {item.point.data.x.slice(7)}
              <br />
              {item.point.data.y} pts
            </div>
          );
        }}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legendOffset: -30,
          legendPosition: "middle",
        }}
        pointSize={10}
        pointColor="#FFFFFF"
        pointBorderWidth={2}
        pointBorderColor="#20296a"
        pointLabelYOffset={-12}
        useMesh={true}
      />
    </div>
  );
};
