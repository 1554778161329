import { ResponsivePie } from "@nivo/pie";

export const MatchsPie = ({ matchs }) => {
  const win = matchs.data.filter((item) => item.victoire === "V").length;
  const loss = matchs.data.length - win;

  return (
    <div className="matchs-pie">
      <ResponsivePie
        colors={{ datum: "data.color" }}
        activeOuterRadiusOffset={8}
        animate
        arcLinkLabelsDiagonalLength={8}
        arcLinkLabelsTextOffset={2}
        theme={{
          fontSize: 18,
          fontWeight: "bold",
        }}
        data={[
          {
            color: "#377EB8",
            id: "Victoires",
            value: win,
          },
          {
            color: "#eb525c",
            id: "Défaites",
            value: loss,
          },
        ]}
        height={500}
        legends={[]}
        arcLabelsTextColor="black"
        margin={{
          bottom: 20,
          left: 112,
          right: 112,
          top: 20,
        }}
      />
    </div>
  );
};
