import { useState } from "react";
import { ffttApiConfig } from "../../resources/ffttApiConfig";
import { getApiRequestData } from "./getApiRequestData";
import { XMLParser } from "fast-xml-parser";
import { useDeepCompareEffect } from "../useDeepCompareEffect";

export default function useFetchFFTT(apiName, customParams, customEndUrl) {
  const [data, setData] = useState({ data: null, status: "LOADING" });
  useDeepCompareEffect(() => {
    async function loadData() {
      if (!localStorage.getItem("serieFFTT")) {
        setTimeout(loadData(), 300);
      } else {
        const { url, configParams, parser } = ffttApiConfig[apiName];
        setData({ data: null, status: "LOADING" });
        const finalUrl = new URL(url);
        const params = getApiRequestData();
        if (configParams) {
          Object.keys(configParams).forEach((item) => {
            params[item] = configParams[item];
          });
        }
        if (customParams) {
          Object.keys(customParams).forEach((item) => {
            params[item] = customParams[item];
          });
        }

        finalUrl.search = new URLSearchParams(params);
        if (customEndUrl) {
          finalUrl.search = finalUrl.search + "&" + customEndUrl;
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain; charset=UTF-8");

        await fetch(
          "https://corsproxy.io/?" + encodeURIComponent(finalUrl.href),
          myHeaders
        )
          .then((response) => response.arrayBuffer())
          .then(async (data) => {
            const decoder = new TextDecoder("iso-8859-1");
            const text = decoder.decode(data);

            setData({
              data: parser(new XMLParser().parse(text)),
              status: "LOADED",
            });
          })
          .catch(console.error);
      }
    }
    loadData();
  }, [apiName, customParams]);
  return data;
}
