import { ResponsivePie } from "@nivo/pie";
import { getClassement } from "./getClassement";

export const MatchsPieDef = ({ playerData, matchs }) => {
  const normalLoss = matchs.data.filter(
    (item) =>
      item.victoire === "D" &&
      getClassement(item.classement) > playerData.ptsOff
  ).length;
  const anormalLoss = matchs.data.filter(
    (item) =>
      item.victoire === "D" &&
      getClassement(item.classement) < playerData.ptsOff
  ).length;

  return (
    <div className="matchs-pie">
      <ResponsivePie
        colors={{ datum: "data.color" }}
        activeOuterRadiusOffset={8}
        arcLinkLabelsDiagonalLength={8}
        arcLinkLabelsTextOffset={2}
        animate
        theme={{
          fontSize: 18,
          fontWeight: "bold",
        }}
        data={[
          {
            color: "#eb525c",
            id: "Contre",
            value: anormalLoss,
          },
          {
            color: "#377EB8",
            id: "Normales",
            value: normalLoss,
          },
        ]}
        height={500}
        legends={[]}
        arcLabelsTextColor="black"
        margin={{
          bottom: 20,
          left: 112,
          right: 112,
          top: 20,
        }}
      />
    </div>
  );
};
