import { DateTime } from "luxon";
import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./article-page.scss";
import { Col, Row } from "../components/grid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "../components/Container";
import Tag from "../components/Tag";
import { getFullPostImage } from "../utils/getPostImage";
import { isLoaded } from "../utils/isLoaded";
import useFetch from "../utils/useFetch";
import { ArticlesSidebar } from "./blocks/ArticlesSidebar";
import ReadMore from "../components/ReadMore";
import { Gallery } from "../components/Gallery";
import LoadingOverlay from "../components/LoadingOverlay";

function Default(props) {
  const navigate = useNavigate();
  const slug = useLocation().pathname.split("/").at(-1);
  const article = useFetch("/wp-json/wp/v2/posts?slug=" + slug);
  const news = useFetch("/wp-json/wp/v2/posts?per_page=3");

  return (
    <>
      <LoadingOverlay isLoaded={isLoaded(article)} />
      <div className="article-page">
        {isLoaded(article) && isLoaded(props.categories) && (
          <Container>
            <Row>
              <Col xl={8}>
                <Row>
                  <div className="article-header">
                    {DateTime.fromISO(article.data[0].date).toFormat(
                      "dd/MM/yyyy"
                    )}
                  </div>
                </Row>
                <Row>
                  <h1>{article.data[0].title.rendered}</h1>
                </Row>
                <Row>
                  <div className="post-tags">
                    {article.data[0].categories.map((catId, key) => {
                      const categoryName = props.categories.data.find(
                        (category) => category.id === catId
                      )?.name;
                      return (
                        <Tag
                          key={key}
                          onClick={() => {
                            props.updateSelectedCategory(catId);
                            navigate("/actualites");
                          }}
                        >
                          {categoryName}
                        </Tag>
                      );
                    })}
                  </div>
                </Row>
                <Row>
                  <img
                    src={getFullPostImage(article.data[0])}
                    className="post-img"
                    alt={article.data[0].slug}
                  />
                </Row>
                <Row>
                  <div
                    className="article-content"
                    dangerouslySetInnerHTML={{
                      __html: article.data[0].content.rendered,
                    }}
                  />
                </Row>
                {article.data[0].acf.image_gallery.length !== 0 && (
                  <Row>
                    <Gallery pictures={article.data[0].acf.image_gallery} />
                  </Row>
                )}
                <Row className="posts-navigation">
                  <div className="previous">
                    {article.data[0].previous && (
                      <Link to={"/actualites/" + article.data[0].previous.slug}>
                        <FaChevronLeft />
                        Article précédent
                      </Link>
                    )}
                  </div>
                  <div className="next">
                    {article.data[0].next && (
                      <Link to={"/actualites/" + article.data[0].next.slug}>
                        Article suivant
                        <FaChevronRight />
                      </Link>
                    )}
                  </div>
                </Row>
              </Col>
              <Col xl={4}>
                <ArticlesSidebar
                  categories={props.categories}
                  onTagSelection={(tagId) => {
                    props.updateSelectedCategory(tagId);
                    props.updateSearch("");
                    navigate("/actualites");
                  }}
                  onSearch={(searchText) => {
                    props.updateSelectedCategory(0);
                    props.updateSearch(searchText);
                    navigate("/actualites");
                  }}
                >
                  <h2>Dernières actualités</h2>
                  <div className="news-list">
                    {isLoaded(news) &&
                      news.data.map((post, key) => (
                        <article key={key}>
                          <Link to={"/actualites/" + post.slug}>
                            <h3>{post.title.rendered}</h3>
                          </Link>
                          <div
                            className="excerpt"
                            dangerouslySetInnerHTML={{
                              __html: post.excerpt.rendered,
                            }}
                          />
                          <ReadMore to={"/actualites/" + post.slug} />
                        </article>
                      ))}
                  </div>
                  <div className="all-news">
                    <Link to="/actualites">
                      <button>Voir toutes les actualités</button>
                    </Link>
                  </div>
                </ArticlesSidebar>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
}

export const ArticlePage = React.memo(Default);
