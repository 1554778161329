import React, { useRef } from "react";
import { FaSearch } from "react-icons/fa";
import Tag from "../../components/Tag";
import { isLoaded } from "../../utils/isLoaded";

function Default(props) {
  const inputElement = useRef();

  return (
    <div className="sidebar">
      <div className="search">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            props.onSearch(inputElement.current.value);
          }}
        >
          <input
            type="text"
            placeholder="Rechercher un article"
            ref={inputElement}
          />
          <FaSearch
            color="#fff"
            onClick={() => props.onSearch(inputElement.current.value)}
          />
        </form>
      </div>
      <div className="categories">
        <h2>Categories</h2>
        <div className="tag-list">
          {isLoaded(props.categories) &&
            props.categories.data.map((category, key) => (
              <Tag key={key} onClick={() => props.onTagSelection(category.id)}>
                {category.name}
              </Tag>
            ))}
        </div>
      </div>
      <div className="sidebar-content">{props.children}</div>
    </div>
  );
}

export const ArticlesSidebar = React.memo(Default);
