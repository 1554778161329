import { customRound } from "../customRound";

export const playersAdapter = (data) => {
  return data.map((item, key) => {
    return {
      licence: item.licence.toString(),
      index: key + 1,
      category: item.category,
      fullName: item.lastName + " " + item.firstName,
      ptsOff: customRound(item.initialPoints),
      ptsAct: customRound(item.points),
      progM: customRound(item.points - item.previousMonthPoints),
      progA: customRound(item.points - item.initialPoints),
    };
  });
};

export const progMutator = (diff) => {
  return diff > 0 ? "+" + diff : diff;
};
