import React from "react";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaFacebookSquare,
  FaInstagram,
} from "react-icons/fa";
import { Row, Col } from "./components/grid";
import { menuLinks } from "./resources/menu";
import Container from "./components/Container";
import { Link } from "react-router-dom";

function Default() {
  const today = new Date();
  return (
    <>
      <div>
        <Container>
          <Row>
            <Col md={12} lg={4} className="address">
              <Row>
                <img
                  src="/logo2.png"
                  alt="logo tt blagnacais"
                  width={134}
                  height={82}
                />
              </Row>
              <Row>
                <FaMapMarkerAlt />
                33 Chemin du Moulin de Naudin
                <br />
                31700 BLAGNAC
              </Row>
              <Row>
                <FaPhoneAlt />
                <a href="tel:0670890186">06 70 89 01 86</a>
              </Row>
              <Row>
                <FaFacebookSquare />
                <a
                  href="https://www.facebook.com/ttblagnacais31"
                  target="_blank"
                  rel="noreferrer"
                >
                  /ttblagnacais31
                </a>
              </Row>
              <Row>
                <FaInstagram />
                <a
                  href="https://www.instagram.com/tt_blagnac_/"
                  target="_blank"
                  rel="noreferrer"
                >
                  /tt_blagnac_
                </a>
              </Row>
            </Col>
            <Col xs={6} lg={2}>
              <strong>Le club</strong>
              <ul>
                {menuLinks
                  .find((item) => item.key === "club")
                  .children.map((menuItem, key) => (
                    <li key={key}>
                      <Link to={menuItem.url}>{menuItem.label}</Link>
                    </li>
                  ))}
              </ul>
            </Col>
            <Col xs={6} lg={2}>
              <strong>Compétitions</strong>
              <ul>
                {menuLinks
                  .find((item) => item.key === "tournaments")
                  .children.map((menuItem, key) => (
                    <li key={key}>
                      <Link to={menuItem.url}>{menuItem.label}</Link>
                    </li>
                  ))}
              </ul>
            </Col>
            <Col xs={6} lg={2}>
              <strong>Partenaires</strong>
              <ul>
                {menuLinks
                  .find((item) => item.key === "partners")
                  .children.map((menuItem, key) => (
                    <li key={key}>
                      <Link to={menuItem.url}>{menuItem.label}</Link>
                    </li>
                  ))}
              </ul>
            </Col>
            <Col xs={6} lg={2}>
              <strong>
                <Link to="/actualites">Actualités</Link>
              </strong>
              <br />
              <br />
              <strong>
                <Link to="/contact">Contact</Link>
              </strong>
              <br />
              <br />
              <strong>
                <a
                  href="https://www.calameo.com/mairie-de-blagnac/read/0038439388ffec82b02a2"
                  target="_blank"
                  rel="noreferrer"
                >
                  Charte équipements sportifs
                </a>
              </strong>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col md={12} lg={3} className="legal-notice">
              <Link to="/mentions-legales">Mentions légales</Link>
            </Col>
            <Col md={12} lg={6} className="copyright">
              © {today.getFullYear()} Tennis de Table Blagnacais. Tous droits
              réservés.
            </Col>
            <Col md={12} lg={3} className="credits">
              Design:{" "}
              <a
                href="https://florencesimonne.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Florence Simonne
              </a>
              <br />
              Développement:{" "}
              <a
                href="https://simon-couderette.com/"
                target="_blank"
                rel="noreferrer"
              >
                Simon Couderette
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export const AppFooter = React.memo(Default);
