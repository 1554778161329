import React from "react";
import { default as cx } from "classnames";
import "./player-page.scss";
import { Col, Row } from "../../components/grid";
import { useLocation } from "react-router-dom";
import Container from "../../components/Container";
import useFetchFFTT from "../../utils/fftt/useFetchFFTT";
import { isLoaded } from "../../utils/isLoaded";
import { customRound } from "../../utils/customRound";
import { Historic } from "./Historic";
import { MatchsPie } from "./MatchsPie";
import { MatchsPieVic } from "./MatchsPieVic";
import { MatchsPieDef } from "./MatchsPieDef";
import { Opponents } from "./Opponents";
import { getEstimationWinLost } from "../../utils/fftt/getEstimationWinLost";

function Default({ players }) {
  const licence = useLocation().pathname.split("/").at(-1);

  const histo = useFetchFFTT("history", {
    numlic: licence,
  });
  const matchsSpid = useFetchFFTT("matchsSpid", {
    numlic: licence,
  });
  const matchsOff = useFetchFFTT("matchsOff", {
    licence: licence,
  });
  const playerData = isLoaded(players)
    ? players.data.find(
        (item) => parseInt(item.licence, 10) === parseInt(licence, 10)
      )
    : {};

  return (
    <div className="player-page">
      {isLoaded(players) && isLoaded(players) && (
        <Container>
          <Row>
            <h1>{playerData.fullName}</h1>
          </Row>
          <Row>
            <Col lg={4} md={6}>
              <div>
                <label>Categorie:</label>
                <span>{playerData.category}</span>
              </div>
              <div>
                <label>Points officiels:</label>
                <span>{playerData.ptsOff}</span>
              </div>
              <div>
                <label>Points actuels:</label>
                <span>{playerData.ptsAct}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div>
                <label>Licence:</label>
                <span>{playerData.licence}</span>
              </div>
              <div>
                <label>Progression annuelle:</label>
                <span
                  className={cx({
                    positive: parseInt(playerData.progA, 10) > 0,
                    negative: parseInt(playerData.progA, 10) < 0,
                  })}
                >
                  {playerData.progA > 0 && "+"}
                  {playerData.progA}
                </span>
              </div>
              <div>
                <label>Progression mensuelle:</label>
                <span
                  className={cx({
                    positive: parseInt(playerData.progM, 10) > 0,
                    negative: parseInt(playerData.progM, 10) < 0,
                  })}
                >
                  {playerData.progM > 0 && "+"}
                  {playerData.progM}
                </span>
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div>
                <label>Pos. club:</label>
                <span>
                  {players.data.findIndex(
                    (item) =>
                      parseInt(item.licence, 10) === parseInt(licence, 10)
                  ) + 1}{" "}
                  / {players.data.length}
                </span>
              </div>
              <div>
                <label>Meilleur classement:</label>
                {isLoaded(histo) && (
                  <span>
                    {Math.max(
                      ...histo.data.liste.histo.map((item) =>
                        parseInt(item.point, 10)
                      )
                    )}
                  </span>
                )}
              </div>
              {isLoaded(matchsSpid) && (
                <div>
                  <label>Moyenne des adversaires:</label>
                  {isLoaded(matchsSpid) && (
                    <span>
                      {customRound(
                        matchsSpid.data.reduce((a, b) => {
                          if (b.classement.toString().includes(" - ")) {
                            return (
                              a + parseInt(b.classement.split("- ").at(-1), 10)
                            );
                          }
                          return a + b.classement;
                        }, 0) / matchsSpid.data.length
                      )}
                    </span>
                  )}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <h2>Historique du classement</h2>
            {isLoaded(histo) && <Historic histo={histo} />}
          </Row>
          {isLoaded(matchsSpid) && isLoaded(matchsOff) && isLoaded(players) && (
            <>
              {matchsSpid.data.length !== 0 ? (
                <>
                  <Row>
                    <Col xl={4}>
                      <h3>Victoires / Défaites</h3>
                      <MatchsPie matchs={matchsSpid} />
                    </Col>
                    <Col xl={4}>
                      <h3>Victoires</h3>
                      <MatchsPieVic
                        playerData={playerData}
                        matchs={matchsSpid}
                      />
                    </Col>
                    <Col xl={4}>
                      <h3>Défaites</h3>
                      <MatchsPieDef
                        playerData={playerData}
                        matchs={matchsSpid}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <h2>Répartition des adversaires</h2>
                  </Row>
                  <Row>
                    <Opponents matchs={matchsSpid} />
                  </Row>
                  {matchsSpid.data.length !== 0 && (
                    <>
                      <Row>
                        <h2>Matchs du mois en cours</h2>
                      </Row>
                      <Row>
                        <table>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Adversaire</th>
                              <th>Coef</th>
                              <th>Gain / Perte (estimés)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {matchsSpid.data
                              .slice(0, -1 * matchsOff.data.length)
                              .map((match, key) => (
                                <tr
                                  key={key}
                                  className={cx({
                                    positive: match.victoire === "V",
                                    negative: match.victoire !== "V",
                                  })}
                                >
                                  <td>{match.date}</td>
                                  <td>
                                    {match.nom} ({match.classement})
                                  </td>
                                  <td>{match.coefchamp}</td>
                                  <td>
                                    {getEstimationWinLost(
                                      match.victoire,
                                      playerData.ptsAct,
                                      match.classement,
                                      match.coefchamp
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </Row>
                    </>
                  )}
                  {matchsOff.data.length !== 0 && (
                    <>
                      <Row>
                        <h2>Matchs des mois précédents</h2>
                      </Row>
                      <Row>
                        <table>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Adversaire</th>
                              <th>Coef</th>
                              <th>Gain / Perte</th>
                            </tr>
                          </thead>
                          <tbody>
                            {matchsOff.data.map((match, key) => (
                              <tr
                                key={key}
                                className={cx({
                                  positive: match.vd === "V",
                                  negative: match.vd !== "V",
                                })}
                              >
                                <td>{match.date}</td>
                                <td>
                                  {match.advnompre} ({match.advclaof})
                                </td>
                                <td>{match.coefchamp}</td>
                                <td>{match.pointres}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Row>
                    </>
                  )}
                </>
              ) : (
                <h2>Pas de match joué cette saison</h2>
              )}
            </>
          )}
        </Container>
      )}
    </div>
  );
}

export const PlayerPage = React.memo(Default);
