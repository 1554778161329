import { getCookieConsentValue } from "react-cookie-consent";

export const trackPageView = () => {
  if (
    getCookieConsentValue("consentCookie") &&
    getCookieConsentValue("consentCookie") !== false
  ) {
    window._paq.push([
      "setCustomUrl",
      window.location.pathname + window.location.search,
    ]);
    window._paq.push(["trackPageView"]);
  }
};

export const trackDocumentDownload = (value) => {
  if (
    getCookieConsentValue("consentCookie") &&
    getCookieConsentValue("consentCookie") !== false
  ) {
    window._paq.push(["trackEvent", "Document", "Download", value]);
  }
};

export const trackButtonClick = (value) => {
  if (
    getCookieConsentValue("consentCookie") &&
    getCookieConsentValue("consentCookie") !== false
  ) {
    window._paq.push([
      "trackEvent",
      "Navigation",
      "Button Click",
      `Origine :  ${value}`,
    ]);
  }
};
