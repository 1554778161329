import { Route, Routes } from "react-router-dom";
import { AppContext } from "./context/AppContext";
import { ArticlePage } from "./pages/ArticlePage";
import { PlayerPage } from "./pages/players/PlayerPage";
import { Suspense, lazy } from "react";

const HomePageEP = lazy(() => import("./routerEP/HomePageEP"));
const PresentationPageEP = lazy(() => import("./routerEP/PresentationPageEP"));
const LocationAndTimesPageEP = lazy(() =>
  import("./routerEP/LocationAndTimesPageEP")
);
const PricesRegisteringPageEP = lazy(() =>
  import("./routerEP/PricesRegisteringPageEP")
);
const StagePageEP = lazy(() => import("./routerEP/StagePageEP"));
const ClothesPageEP = lazy(() => import("./routerEP/ClothesPageEP"));
const TeamChampionshipEP = lazy(() => import("./routerEP/TeamChampionshipEP"));
const PlayersPageEP = lazy(() => import("./routerEP/PlayersPageEP"));
const SponsorsPageEP = lazy(() => import("./routerEP/SponsorsPageEP"));
const SupportUsPageEP = lazy(() => import("./routerEP/SupportUsPageEP"));
const ContactPageEP = lazy(() => import("./routerEP/ContactPageEP"));
const CriteriumPageEP = lazy(() => import("./routerEP/CriteriumPageEP"));
const NewsPageEP = lazy(() => import("./routerEP/NewsPageEP"));
const LegalNoticesPageEP = lazy(() => import("./routerEP/LegalNoticesPageEP"));

export const AllRoutes = () => {
  return (
    <Routes>
      <Route
        path="actualites/:articleSlug"
        element={
          <AppContext.Consumer>
            {(props) => <ArticlePage {...props} />}
          </AppContext.Consumer>
        }
      />
      <Route
        path="joueurs/:licence"
        element={
          <AppContext.Consumer>
            {(props) => <PlayerPage players={props.players} />}
          </AppContext.Consumer>
        }
      />
      <Route
        path="/"
        element={
          <Suspense fallback={<></>}>
            <HomePageEP />
          </Suspense>
        }
      />
      <Route
        path="/le-club"
        element={
          <Suspense fallback={<></>}>
            <PresentationPageEP />
          </Suspense>
        }
      />
      <Route
        path="/lieu-horaires"
        element={
          <Suspense fallback={<></>}>
            <LocationAndTimesPageEP />
          </Suspense>
        }
      />
      <Route
        path="/inscriptions-tarifs"
        element={
          <Suspense fallback={<></>}>
            <PricesRegisteringPageEP />
          </Suspense>
        }
      />
      <Route
        path="/stages-enfants"
        element={
          <Suspense fallback={<></>}>
            <StagePageEP />
          </Suspense>
        }
      />
      <Route
        path="/maillots-vetements"
        element={
          <Suspense fallback={<></>}>
            <ClothesPageEP />
          </Suspense>
        }
      />
      <Route
        path="/championnat-par-equipes"
        element={
          <Suspense fallback={<></>}>
            <TeamChampionshipEP />
          </Suspense>
        }
      />
      <Route
        path="/criterium-federal"
        element={
          <Suspense fallback={<></>}>
            <CriteriumPageEP />
          </Suspense>
        }
      />
      <Route
        path="/joueurs"
        element={
          <Suspense fallback={<></>}>
            <PlayersPageEP />
          </Suspense>
        }
      />
      <Route
        path="/partenaires"
        element={
          <Suspense fallback={<></>}>
            <SponsorsPageEP />
          </Suspense>
        }
      />
      <Route
        path="/nous-soutenir"
        element={
          <Suspense fallback={<></>}>
            <SupportUsPageEP />
          </Suspense>
        }
      />
      <Route
        path="/actualites"
        element={
          <Suspense fallback={<></>}>
            <NewsPageEP />
          </Suspense>
        }
      />
      <Route
        path="/contact"
        element={
          <Suspense fallback={<></>}>
            <ContactPageEP />
          </Suspense>
        }
      />
      <Route
        path="/mentions-legales"
        element={
          <Suspense fallback={<></>}>
            <LegalNoticesPageEP />
          </Suspense>
        }
      />
    </Routes>
  );
};
