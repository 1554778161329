import { default as cx } from "classnames";
import { ThreeCircles } from "react-loader-spinner";

function LoadingOverlay({ children, isLoaded }) {
  return (
    <div className="loading-overlay-wrapper">
      <div
        className={cx("loading-overlay", {
          hidden: isLoaded,
        })}
      >
        <ThreeCircles
          height="160"
          width="160"
          color="#ee3962"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor=""
          innerCircleColor=""
          middleCircleColor=""
        />
      </div>
      {children}
    </div>
  );
}

export default LoadingOverlay;
