import React, { useState } from "react";
import { Gallery as GridGallery } from "react-grid-gallery";
import { Lightbox } from "../components/Lightbox";

function Default({ pictures }) {
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setViewerIsOpen(false);
  };

  const photosForGallery = pictures.map((image) => ({
    src: image.medium[0],
    width: image.medium[1],
    height: image.medium[2],
  }));

  const lightboxImages = pictures.map((image) => image.lightboxbig[0]);

  return (
    <>
      <div id="gallery-ttb">
        {viewerIsOpen && (
          <Lightbox
            images={lightboxImages}
            currentImageIndex={currentImage}
            onClose={closeLightbox}
          />
        )}
        <GridGallery
          images={photosForGallery}
          enableImageSelection={false}
          rowHeight={250}
          onClick={openLightbox}
          margin={8}
        />
      </div>
    </>
  );
}

export const Gallery = React.memo(Default);
