import { XMLParser } from "fast-xml-parser";
import { getApiRequestData } from "./getApiRequestData";

export const initializeFFTT = async (serie) => {
  const params = getApiRequestData();
  const finalUrl = new URL(
    "http://www.fftt.com/mobile/pxml/xml_initialisation.php"
  );
  params.serie = serie;
  finalUrl.search = new URLSearchParams(params);
  await fetch(finalUrl.href)
    .then((response) => response.text())
    .then((data) => {
      const formattedData = new XMLParser().parse(data);
      if (formattedData.initialisation.appli === 1) {
        localStorage.setItem("serieFFTT", serie);
        return true;
      }
    })
    .catch(console.error);
};
