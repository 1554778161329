import { useEffect, useRef } from "react";

function deepCompareEquals(a, b) {
  if (typeof a === "object") {
    const keys = Object.keys(a);
    return keys.every((key) => a?.[key] === b?.[key]);
  }
  return a === b;
}

function useDeepCompareMemoize(value) {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export const useDeepCompareEffect = (callback, dependencies) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};
