import { Scrollbars } from "react-custom-scrollbars-2";
import { useLocation } from "react-router-dom";
import { AppHeader } from "./Header";
import { AppFooter } from "./Footer";
import { useEffect, useRef, useState } from "react";
import { AppContext } from "./context/AppContext";
import useFetch from "./utils/useFetch";
import { initializeFFTT } from "./utils/fftt/initializeFFTT";
import { playersAdapter } from "./utils/fftt/playersAdapter";
import { AllRoutes } from "./AllRoutes";
import { getTitleTagPerPage } from "./utils/gitTitleTagPerPage";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { trackPageView } from "./utils/sendTracking";

function App() {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedCategory, updateSelectedCategory] = useState(0);
  const [search, updateSearch] = useState("");

  let location = useLocation();

  const serie = useRef(
    localStorage.getItem("serieFFTT")
      ? localStorage.getItem("serieFFTT")
      : Array.from(Array(15), () =>
          Math.floor(Math.random() * 36).toString(36)
        ).join("")
  );

  useEffect(() => {
    if (
      getCookieConsentValue("consentCookie") &&
      getCookieConsentValue("consentCookie") !== false
    ) {
      initTracking();
    }
  }, []);

  const initTracking = () => {
    window._paq.push(["setConsentGiven"]);
    window._paq.push(["enableLinkTracking"]);

    trackPageView();
  };

  useEffect(() => {
    document.title = getTitleTagPerPage(location.pathname);
    trackPageView();

    scrollbarRef.current.view.scroll({
      top: 0,
    });
  }, [location]);

  if (!localStorage.getItem("serieFFTT")) {
    initializeFFTT(serie.current);
  }

  const scrollbarRef = useRef();

  const categories = useFetch("/wp-json/wp/v2/categories", "cat");

  const players = useFetch(
    "/wp-json/playersFFTT/v1/all/1",
    false,
    0,
    playersAdapter
  );

  const updateScrollPosition = () => {
    const newPosition = scrollbarRef.current.getScrollTop();
    setScrollPosition(newPosition);
    if (newPosition > 80 && !hasScrolled) {
      setHasScrolled(true);
    } else if (newPosition < 80 && hasScrolled) {
      setHasScrolled(false);
    }
  };

  return (
    <AppContext.Provider
      value={{
        search,
        selectedCategory,
        updateSearch,
        updateSelectedCategory,
        categories: categories,
        scrollbarRef,
        hasScrolled,
        scrollPosition,
        // Pages content
        players,
      }}
    >
      <CookieConsent
        location="bottom"
        buttonText="J'accepte"
        cookieName="consentCookie"
        style={{ background: "#0f1333" }}
        declineButtonText="Je refuse"
        declineCookieValue={false}
        enableDeclineButton={true}
        buttonStyle={{
          color: "#fff",
          backgroundColor: "#ee3962",
        }}
        declineButtonStyle={{
          color: "#fff",
          backgroundColor: "transparent",
        }}
        expires={90}
        onAccept={initTracking}
      >
        Ce site récupère des données anonymisées dans le but d'améliorer
        l'expérience utilisateur.{" "}
      </CookieConsent>
      <Scrollbars
        ref={scrollbarRef}
        className="scrollbar"
        autoHide
        onScroll={updateScrollPosition}
        autoHideTimeout={2000}
        autoHideDuration={800}
      >
        <div className="content-wrapper">
          <AppHeader />
          <section id="content">
            <AllRoutes />
          </section>
          <footer>
            <AppFooter />
          </footer>
        </div>
      </Scrollbars>
    </AppContext.Provider>
  );
}

export default App;
