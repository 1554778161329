import React, { useState, useEffect } from "react";
import "./Lightbox.scss";

export const Lightbox = ({ images, currentImageIndex, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(currentImageIndex);

  useEffect(() => {
    setIsOpen(true);

    const handleKeyDown = (e) => {
      if (e.key === "ArrowRight") {
        showNext(e);
      } else if (e.key === "ArrowLeft") {
        showPrev(e);
      } else if (e.key === "Escape") {
        handleClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex]);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 500); // Attendez la fin de l'animation avant de fermer
  };

  const showNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const showPrev = (e) => {
    e.stopPropagation();
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  return (
    <div
      className={`lightbox-overlay ${isOpen ? "open" : ""}`}
      onClick={handleClose}
    >
      <span className="lightbox-close" onClick={handleClose}>
        &times;
      </span>
      <button className="lightbox-prev" onClick={showPrev}>
        &#10094;
      </button>
      <button className="lightbox-next" onClick={showNext}>
        &#10095;
      </button>
      <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
        <div className="lightbox-image-container">
          <img
            src={images[currentIndex]}
            alt={`Slide ${currentIndex}`}
            className="lightbox-image"
          />
        </div>
      </div>
    </div>
  );
};
