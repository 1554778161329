export const getEstimationWinLost = (vic, pts, ptsAdv, coef) => {
  const isVic = vic === "V";
  const gap = Math.floor(pts - ptsAdv);
  let res = 0;

  if (isVic && gap >= 0) {
    res = pointsPerGapNormVic.find(
      (item) => item.min <= gap && item.max >= gap
    ).points;
  }
  if (isVic && gap < 0) {
    res = pointsPerGapAnormVic.find(
      (item) => item.max <= gap && item.min >= gap
    ).points;
  }
  if (!isVic && gap >= 0) {
    res = pointsPerGapAnormDef.find(
      (item) => item.min <= gap && item.max >= gap
    ).points;
  }
  if (!isVic && gap < 0) {
    res = pointsPerGapNormDef.find(
      (item) => item.max <= gap && item.min >= gap
    ).points;
  }
  res = res * coef;

  return res > 0 ? "+" + res : res;
};

const pointsPerGapNormVic = [
  { min: 0, max: 24, points: 6 },
  { min: 25, max: 49, points: 5.5 },
  { min: 50, max: 99, points: 5 },
  { min: 100, max: 149, points: 4 },
  { min: 150, max: 199, points: 3 },
  { min: 200, max: 299, points: 2 },
  { min: 300, max: 399, points: 1 },
  { min: 400, max: 499, points: 0.5 },
  { min: 500, max: 10000, points: 0 },
];

const pointsPerGapAnormVic = [
  { min: 0, max: -24, points: 6 },
  { min: -25, max: -49, points: 7 },
  { min: -50, max: -99, points: 8 },
  { min: -100, max: -149, points: 10 },
  { min: -150, max: -199, points: 13 },
  { min: -200, max: -299, points: 17 },
  { min: -300, max: -399, points: 22 },
  { min: -400, max: -499, points: 28 },
  { min: -500, max: -10000, points: 40 },
];

const pointsPerGapNormDef = [
  { min: 0, max: -24, points: -5 },
  { min: -25, max: -49, points: -4.5 },
  { min: -50, max: -99, points: -4 },
  { min: -100, max: -149, points: -3 },
  { min: -150, max: -199, points: -2 },
  { min: -200, max: -299, points: -1 },
  { min: -300, max: -399, points: -0.5 },
  { min: -400, max: -499, points: 0 },
  { min: -500, max: -10000, points: 0 },
];

const pointsPerGapAnormDef = [
  { min: 0, max: 24, points: -5 },
  { min: 25, max: 49, points: -6 },
  { min: 50, max: 99, points: -7 },
  { min: 100, max: 149, points: -8 },
  { min: 150, max: 199, points: -10 },
  { min: 200, max: 299, points: -12.5 },
  { min: 300, max: 399, points: -16 },
  { min: 400, max: 499, points: -20 },
  { min: 500, max: 10000, points: -29 },
];
