import React, { useState } from "react";
import { default as cx } from "classnames";
import { Link } from "react-router-dom";
import Container from "./components/Container";
import { AppContext } from "./context/AppContext";
import { MainMenu } from "./pages/blocks/MainMenu";
import { Turn as Hamburger } from "hamburger-react";

function Default() {
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  return (
    <AppContext.Consumer>
      {({ hasScrolled }) => (
        <>
          <header className={cx("fixed-header", { scrolled: hasScrolled })}>
            <Container>
              <div className={"hamburger-react-wrapper"}>
                <Hamburger
                  size={44}
                  toggled={mobileMenuOpened}
                  toggle={setMobileMenuOpened}
                />
              </div>
              <Link to="/" onClick={() => setMobileMenuOpened(false)}>
                <img
                  src="/logo2.png"
                  alt="logo tt blagnacais"
                  width={162}
                  height={100}
                />
              </Link>

              <div className="menu-wrapper">
                <MainMenu setMobileMenuOpened={setMobileMenuOpened} />
              </div>
            </Container>
          </header>
          <div className="mobile-menu">
            <MainMenu
              mobileMenuOpened={mobileMenuOpened}
              setMobileMenuOpened={setMobileMenuOpened}
            />
          </div>
        </>
      )}
    </AppContext.Consumer>
  );
}

export const AppHeader = React.memo(Default);
