import { default as cx } from "classnames";

function Container(props) {
  return (
    <div
      className={cx("container-custom", {
        [props.className]: props.className,
      })}
      style={props.style}
    >
      {props.children}
    </div>
  );
}

export default Container;
