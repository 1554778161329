import { DateTime } from "luxon";
import { getEncryptedTimestamp } from "./getEncryptedTimestamp";

export const getApiRequestData = () => {
  const id = "SW040";
  const password = "PqEr824fHt";
  const currentDate = DateTime.now();

  const timestamp = currentDate.toFormat("yyyyMMddHHmmssSSS");
  const encryptedTimestamp = getEncryptedTimestamp(timestamp, password);

  return {
    tm: timestamp,
    tmc: encryptedTimestamp,
    id: id,
    serie: localStorage.getItem("serieFFTT"),
  };
};
