export const getTitleTagPerPage = (page) => {
  let pageTitle = "TT Blagnacais";
  switch (page) {
    case "/le-club":
      return "Le club - " + pageTitle;

    case "/lieu-horaires":
      return "Lieu & Horaires - " + pageTitle;

    case "/inscriptions-tarifs":
      return "Inscriptions - " + pageTitle;

    case "/stages-enfants":
      return "Stages enfants - " + pageTitle;

    case "/maillots-vetements":
      return "Maillots et vêtemennts - " + pageTitle;

    case "/championnat-par-equipes":
      return "Championnat par équipes - " + pageTitle;

    case "/criterium-federal":
      return "Critérium fédéral - " + pageTitle;

    case "/joueurs":
      return "Joueurs - " + pageTitle;

    case "/partenaires":
      return "Partenaires - " + pageTitle;

    case "/nous-soutenir":
      return "Nous soutenir - " + pageTitle;

    case "/actualites":
      return "Actualités - " + pageTitle;

    case page.startsWith("/actualites/") ? page : "BAD_ONE":
      return "Actualité - " + pageTitle;

    case "/contact":
      return "Contactez-nous - " + pageTitle;

    default:
      return pageTitle;
  }
};
