import { ResponsiveBar } from "@nivo/bar";
import { onlyUnique } from "../../utils/onlyUnique";
import { getClassement } from "./getClassement";

export const Opponents = ({ matchs }) => {
  const rankings = matchs.data
    .map((item) =>
      parseInt(getClassement(item.classement).toString().slice(0, -2), 10)
    )
    .filter(onlyUnique)
    .sort((a, b) => a - b);

  const data = rankings.map((rank) => ({
    ranking: rank,
    Victoire: matchs.data.filter(
      (item) =>
        parseInt(getClassement(item.classement).toString().slice(0, -2), 10) ===
          rank && item.victoire === "V"
    ).length,
    VictoireColor: "#377EB8",
    Defaite: matchs.data.filter(
      (item) =>
        parseInt(getClassement(item.classement).toString().slice(0, -2), 10) ===
          rank && item.victoire !== "V"
    ).length,
    DefaiteColor: "#eb525c",
  }));

  return (
    <div className="opponents-chart">
      <ResponsiveBar
        data={data}
        colors={({ id, data }) => String(data[`${id}Color`])}
        keys={["Victoire", "Defaite"]}
        indexBy="ranking"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        enableGridX={true}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Classement adversaire",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        theme={{
          fontSize: 16,
          axis: {
            legend: {
              text: {
                fontSize: 18,
              },
            },
          },
        }}
        tooltip={(item) => {
          return (
            <div className="tooltip-graph">
              {item.id}
              {item.value > 1 && "s"} vs. {item.indexValue}
              <br />
              <strong>{item.value}</strong>
            </div>
          );
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        role="application"
        ariaLabel="Nivo bar chart demo"
      />
    </div>
  );
};
